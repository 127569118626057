<template>
  <div
    class="box" 
    :class="{'question-isErr': isError}">


    <!-- body -->
    <div class='block'>
      
      <div class='columns'>
        <div class='column'>

          <!-- title -->
          <div class='block'>
            <h4 class='' v-html='question.text' />
          </div>
          

          <!-- response items if d0 -->
          <div
            v-if='domainId === "d0"'>

            <!-- if dropdown -->
            <div v-if='question.type === "dropdown"'>
              <div class="select" :class="{'is-info': activeOpts !== null}">
                <select @change="select($event.target.value)">
                  <option selected disabled hidden>Select a state</option>
                  <option v-for="(opt, o) in question.options" 
                    :key="'o'+o"
                    :value='opt.val'>{{opt.val}}
                  </option>
                </select>
              </div>
            </div>

            <!-- if normal -->
            <div 
              v-else  
              class='button-div' 
              v-for="(opt, o) in question.options" 
              :key="'o'+o">
              
              <button 
                class='button wrap is-fullwidth has-text-left' 
                :class="{'is-info': activeOpts === opt.val, 'is-hovered': isHovering === o}" 
                @click='select(opt.val)' 
                @mouseover='isHovering = o' 
                @mouseout='isHovering = false'>{{opt.disp}}</button>
            </div>          
          </div>


          <!-- response items if not d0 -->
          <div v-else>
            <div class='columns'>
              <!-- <div class='column is-one-third'> -->
                <div class='button-div column' v-for="(opt, o) in question.options" :key="'o'+o">
                  <button 
                    class='button wrap is-fullwidth has-text-center' 
                    :class="{'is-info': activeOpts === opt.val, 'is-hovered': isHovering === o}" 
                    @click='select(opt.val)' 
                    @mouseover='isHovering = o' 
                    @mouseout='isHovering = false'>{{opt.disp}}</button>
                </div>
              <!-- </div> -->
            </div>
          </div>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: [
    'question',
    'domainId',
    'isError'
  ],
  data() {
    return {
      // options that the user has currently selected
      activeOpts: null,
      isHovering: false,
    }
  },
  computed: {
    ...mapState(['questionRules']),
     questionFeedback() {
      let relevantFeedback = [];

      // find the feedback for this question
      const thisQuestionFeedback = this.questionRules.filter(x => x.id === this.config.id);
      
      // if it exists, open it up 
      if (thisQuestionFeedback.length > 0) {
        relevantFeedback = thisQuestionFeedback[0].maps.filter(x => this.activeOpts.includes(x.opt)).map(x => x.message)
      }
      return relevantFeedback
    }
  },
  methods: {
    ...mapMutations(['updateCurrentResponses']),
    select(i) {
      // handle user selecting an option

      // if this option was already selected, deselect it
      // otherwise select it
      this.activeOpts = this.activeOpts === i ? null : i;

      // update global choices for scoring
      const userSelections = {
        qId: this.question.qId,
        val: this.activeOpts
      }
      this.updateCurrentResponses(userSelections) 

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
